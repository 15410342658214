import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Painting } from '../Painting';
import { ModalContentComponent } from '../modal-content/modal-content.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
    
    @Input() 
    paintings;

    @ViewChild(ModalContentComponent, {static : false})
    modalContentComponent : ModalContentComponent;

  
  constructor() { }

  ngOnInit() {
    $(document).ready(function(){

      $(".card").hover(
          function(){
              $(this).addClass('shadow').css('cursor','pointer');
          }, function(){
              $(this).removeClass('shadow');
          }
      
      );
      
      $("a").hover(
          function(){
              $(this).addClass('text-primary');
          
          }
      )
      
      });
      
  }

  clickResponse(){
    this.modalContentComponent.loadComponent = true;
  }



}

