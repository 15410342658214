import { Component, Input ,OnInit } from '@angular/core';
import { CardComponent } from '../card/card.component';


@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {

  public loadComponent;

  @Input() 
  paintingModal;

  constructor( ) { }

  ngOnInit() {
  }

  closeResponse(){
    this.loadComponent = false;
  }

}
