import { Painting } from './Painting' ;


export const PAINTING: Painting[] = [
    {   id: 1, 
        title: "Abstract", 
        description: "Acrylic on canvas, 70x90cm",
        date: "2018",    
        image: "./assets/img/Abstract2018.JPEG"
    },
    {   id: 2, 
        title: "Geisha", 
        description: "Acrylic on canvas, 70x90cm.", 
        date: "2019",
        image: "./assets/img/Geisha2019.JPEG"
    },
    {   id: 3, 
        title: "Oepts-study", 
        description: "Water based Oil on paper, 20x30cm.", 
        date: "2020",
        image: "./assets/img/Oepts2020.JPEG"
    },
    {   id: 4, 
        title: "Peonies", 
        description: "Acrylic on Cancas, 40x50cm.", 
        date:"2018",
        image: "./assets/img/Peonies2018.JPEG"
    },
    {   id: 5, 
        title: "Sri Lanka", 
        description: "Water based Oil on canvas, 30X40cm. ",
        date:"2019", 
        image: "./assets/img/SriLank2019.JPEG"
    },
    {   id: 6, 
        title: "Toucan", 
        description: "Acrylic on canvas, 40x60cm", 
        date: "2018",
        image: "./assets/img/Toucan2018.JPEG"
    },
    {   id: 7, 
        title: "Delft In The Golden Age", 
        description: "Acrylic on canvas, 40x60cm", 
        date: "2019",
        image: "./assets/img/Delft2019.JPEG"
    },
    {   id: 8, 
        title: "Van Gogh Study", 
        description: "Acrylic on canvas, 40x60cm", 
        date: "2015",
        image: "./assets/img/nanGoghStudy2015.JPEG"
    },
    {   id: 9, 
        title: "Woman With The Parasol Reinvented", 
        description: "Water based Oil on canvas, 30x40cm", 
        date: "2020",
        image: "./assets/img/MonetInNieuwJasje2020.JPEG"
    }
    
    
]