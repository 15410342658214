import { Component, Input ,OnInit } from '@angular/core';
import {PAINTING} from '../mockPaintingList'


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  @Input() paintings = PAINTING;

}
